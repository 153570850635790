<template>
  <div class="bottom-fixed-bar" ref="bottomBar" >
      <slot></slot>
  <div class="bottom-navigation">
    <a class="nav-item" @click="goTo('sport')">
      <!-- <i class="bi bi-list"></i> -->
      <img src="/img/BottomNav/home.png" class="menu-icon-svg">
      <span class="item-name">Menu</span>
    </a>

    <router-link to="/live" class="nav-item">
      <div class="icon">
          <img src="/img/BottomNav/PlayButton.svg" class="menu-icon-svg">

          <span
            class="custom-badge">
            {{ liveGames }}
          </span>
      </div>
     
      <span class="item-name">Live</span>
    </router-link>

    <!-- <div class="major-counter" data-toggle="modal" data-target="#showBetSlip">
      <span>{{ betslip_count }}</span>
    </div> -->

    <router-link to="/my-bets" class="nav-item">
      <div class="icon">
      <img  src="/img/BottomNav/Ticket.svg" class="menu-icon-svg">
      
        <span
          class="custom-badge">
          {{ normalBetsCount }}
        </span>
      </div>
      <span class="item-name">My Bets</span>
    </router-link>

    <router-link v-show="isLoggedIn" to="/my-account" class="nav-item">
      <img src="/img/BottomNav/User.svg" class="menu-icon-svg">
      <span class="item-name">Account</span>
    </router-link>

    <router-link v-show="!isLoggedIn" to="/login" class="nav-item">
      <img src="/img/BottomNav/User.svg" class="menu-icon-svg">
      <span  class="item-name">Login</span>
    </router-link>
  </div>
</div>
</template>

<script>
  let observer = null;
  export default {
      name: 'BottomNavigationMenu',
      props:['isLoggedIn','normalBetsCount','betslip_count','liveGames'],
     mounted() {
      this.getBottomBarHeight();
      observer = new ResizeObserver(this.getBottomBarHeight).observe(this.$refs.bottomBar);
      
     },
     beforeDestroy() {
      console.log('unmounted');
      document.documentElement.style.setProperty('--bottom-bar-height', '0px');
      if(observer){
          observer.disconnect();
      }


     },
     methods: {
      getBottomBarHeight() {
          const height = this.$refs.bottomBar.clientHeight;
          document.documentElement.style.setProperty('--bottom-bar-height', `${height + 20}px`);
      },
     }
  }
</script>

<style scoped>
.bottom-fixed-bar {
  position: fixed;
bottom: 0;
left: 0;
right: 0;
transition: transform 0.3s ease-in-out;
max-width: 768px;
margin: 0 auto;
display: flex;
flex-direction: column;
}
.bottom-navigation {

background-color: var(--primary-dark);
display: flex;
justify-content: space-between;
align-items: center;
padding: 1rem;

border-top: 1px solid var(--primary-bg-light);
}

.bottom-navigation.sticky {
transform: translateY(0);
}


.custom-badge {
  position: absolute;
 right: -15px;
 bottom: -5px;
 padding: 1px;

  font-size: 13px; border-radius: 50%; display: inline-block; width: 18px; height: 18px; text-align: center; line-height: 12px;
  border-radius: 9999px;
  aspect-ratio: 1/1;
 display: flex;
 align-items: center;
 justify-content: center;
}
.nav-item {
display: flex;
position: relative;
flex-direction: column;
align-items: center;
text-decoration: none;
color: #fff;
line-height: 1;
gap: 0.7rem;
}
.nav-item .icon {
  position: relative;
}

.nav-item>i {
font-size: 20px;
margin-right: 10px;
}

.nav-item img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}

.nav-item>span {
font-size: 14px;
}

</style>