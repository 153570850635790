<template>
  <div class="betslip-summary">
    <div class="row m-0 p-0">
      <div class="col-6 m-0 p-0">
        <div class="dets-title text-dark">
          Total Odds
        </div>
        <div class="text-dark small-text">
          Min. 1.075
        </div>
      </div>
      <div class="col-6 m-0 p-0">
        <div class="dets-value text-dark text-right">
          {{ betslip.odds | formatOdds }}
        </div>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-6 m-0 p-0">
        <div class="dets-title text-dark">
          Final Payout
        </div>
        <div class="text-dark small-text hover-pointer" data-toggle="collapse" href="#collapseDetails" role="button"
          aria-expanded="false" aria-controls="collapseDetails">
          <i class="bi bi-info-circle"></i> Details
        </div>
      </div>
      <div class="col-6 m-0 p-0">
        <div class="dets-value text-dark text-right">
          {{ betslip.payout | currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetSlipSummary',
  props: {
    betslip: {
      required: true,
      type: Object
    }
  },

}
</script>

<style scoped>
.betslip-summary {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 0.5rem;

}

.betslip-summary>div {
  border-bottom: 1px var(--light-gray) solid;

}

.betslip-summary>div:last-child {
  border-bottom: none;
}
</style>